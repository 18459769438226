import React, { useEffect, useState } from 'react';
import s from './style.scss';
import FacetFilter from '../FacetFilter';
import loadash from 'lodash';
import BreadCrumb from '../BreadCrumb';
import WsmFitmentSelectorWrapper from '../WsmFitmentSelectorWrapper';
import WsmProductListWrapper from '../WsmProductListWrapper';

type Props = {
  /** Id of the fitment group your site uses for fitment.*/
  groupId: number;
  /** Will determine the search used for the results */
  searchString?: string;
  /** {"facet": "facetValue"} object, will condition search with an initial facet selection. */
  initialFacets?: any;
  /** If set, will display above the fitment selector.*/
  fitmentSelectorTitle?: string;
  /** Label of the search button under the fitment selector. Has a default value.*/
  fitmentSelectorSearchButton?: string;
  /** Label of the clear button under the fitment selector. Has a default value.*/
  fitmentSelectorClearButton?: string;
  /** Text to show when no results are returned. Has a default value.*/
  noResultsText?: string;
  /** Text to show above the qualifiers section. Has a default value.*/
  optionalLabelsTitle?: string;
  /** Determines if optional qualifiers will be used or not. Defaults to "disabled".*/
  qualifiersStatus?: 'enabled' | 'disabled';
};
const ResultsPage = ({
  /* Description */
  groupId,
  searchString,
  initialFacets = {},
  fitmentSelectorSearchButton = 'Search',
  fitmentSelectorClearButton = 'Clear',
  noResultsText,
  fitmentSelectorTitle,
  optionalLabelsTitle,
  qualifiersStatus = 'enabled',
}: Props) => {
  const [fitment, setFitment] = useState<null | string>(null);
  const [facets, setFacets] = useState<any>(initialFacets);
  const [facetOptions, setFacetOptions] = useState<any>({});
  const [search, setSearch] = useState<string>(searchString);
  const [checkingFitment, setCheckingFitment] = useState(true);
  const [parsedInitialFacets, setParsedInitialFacets] = useState({});

  useEffect(() => {
    if (initialFacets) {
      const obj = {};
      for (const key in initialFacets) {
        obj[key] = [initialFacets[key]];
      }
      if (!loadash.isEmpty(obj)) {
        setParsedInitialFacets(obj);
      }
    }
  }, [initialFacets]);

  useEffect(() => {
    setSearch(searchString);
  }, [searchString]);

  useEffect(() => {
    const ftm = localStorage.getItem('sui-fitment-selector-v2');
    if (ftm) {
      setFitment(JSON.parse(ftm)[0]);
    }
    setCheckingFitment(false);
  }, []);

  return (
    <div className={s['results-page-container']}>
      <div className={s['top-section']}>
        <BreadCrumb
          filters={
            search
              ? [
                  {
                    color: '#f8f8f8',
                    id: 1,
                    label: `Keyword: ${search}`,
                  },
                ]
              : []
          }
          onRemoveFilter={() => {
            setSearch('');
          }}
          paths={{
            list: [
              {
                label: 'Home',
                url: '/',
              },
              {
                label: 'Search',
                url: window.location.pathname,
              },
            ],
            separator: '/',
          }}
        />
      </div>
      <div className={s['bottom-section']}>
        <div className={s['left-column']}>
          <div
            className={`${s['fitment-selector-container']} pl-fitment-selector-section`}
          >
            {fitmentSelectorTitle && (
              <div className={`${s['title']} pl-fitment-selector-title`}>
                {fitmentSelectorTitle}
              </div>
            )}
            <WsmFitmentSelectorWrapper
              groupId={groupId}
              orientation="vertical"
              styled
              className={s['fitment-selector']}
              onSubmit={(ftm) => {
                setFitment(ftm);
              }}
              onClearCb={() => {
                setFitment(null);
              }}
              qualifiersStatus={qualifiersStatus}
              searchButtonText={fitmentSelectorSearchButton}
              clearButtonText={fitmentSelectorClearButton}
              optionalLabelsTitle={optionalLabelsTitle}
              loadingQualifiersText=""
            />
          </div>
          <FacetFilter
            enableCollapse
            styled
            onChange={(e) => {
              if (!loadash.isEqual(e.selectedValues, facets)) {
                setFacets(e.selectedValues);
              }
            }}
            selectedValues={parsedInitialFacets}
            data={facetOptions}
          />
        </div>
        <div className={s['results-container']}>
          {!checkingFitment && (
            <WsmProductListWrapper
              search={search}
              styled
              showPagination
              showControls
              showLoadingIndicator
              renderNoResults={
                noResultsText
                  ? () => (
                      <h4 style={{ textAlign: 'center' }}>{noResultsText}</h4>
                    )
                  : undefined
              }
              onDataReceived={(e) => {
                const parsedFacets = {};
                e.facets.forEach((item) => {
                  const facet = {
                    title: item.name,
                    values: item.values,
                  };
                  parsedFacets[item.name] = facet;
                });
                if (!loadash.isEqual(facetOptions, parsedFacets)) {
                  setFacetOptions(parsedFacets);
                }
              }}
              query={{ fitment, ...facets }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
